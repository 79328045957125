@use 'themes/components'; // your own components*/
@use '@angular/material' as mat;
@use 'node_modules/@onyx/core/src/lib/themes' as core;
@use 'node_modules/@onyx/shell/src/lib/public-components' as shell-components;

@include core.themes(); //include the themes for the core ui components
@include core.apply-themes using($theme) {
  @include components.component-theme($theme);
  @include shell-components.component-themes($theme);
}

@import '@onyx/core';

// global css
.clickable {
  cursor: pointer;
}
